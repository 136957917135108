/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DecisionAffiliated = {
    /**
     * status of decision
     */
    status: DecisionAffiliated.status;
};
export namespace DecisionAffiliated {
    /**
     * status of decision
     */
    export enum status {
        OK = 'OK',
        BLOCKING = 'BLOCKING',
    }
}

