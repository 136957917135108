import React, { useState } from "react";
import Button from "@mui/material/Button";

import WarningIcon from "../../images/warning-circle.png";
import BlockedIcon from "../../images/warning-triangle-solid.png";
import "./NotNowButton.css";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface NotNowButtonProps {
  onClick: () => void;
  blocked: boolean;
}

const NotNowButton: React.FC<NotNowButtonProps> = ({ onClick, blocked }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className="notNowButtonContainer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!blocked ? (
        <>
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Button className="notNowButton" onClick={onClick}>
              <Typography className="skip-text">{t("Token.skip")}</Typography>
            </Button>
          </div>
          <div className={`warningMessage ${isHovered ? "visible" : ""}`}>
            <img src={WarningIcon} alt="Warning" className="warningIcon" />
            <span>{t("Token.skipWarning")}</span>
          </div>
        </>
      ) : (
        <Typography className="body1">
          <img src={BlockedIcon} alt="Warning" className="warningIcon" />
          <span>{t("Token.blockedWarning")}</span>
        </Typography>
      )}
    </div>
  );
};

export default NotNowButton;
