import Typography from "@mui/material/Typography";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Organization } from "../../API/XFA_DEVICE_API";

export interface SuccessSectionProps {
  t: (key: string) => string;
}

export const RedirectingSection: React.FC<SuccessSectionProps> = ({ t }) => (
  <div className="general">
    <Typography className="header">{t("Token.checking")}</Typography>
    <div className="success">
      <CheckCircleIcon className="icon SuccessIcon" />
      <Typography className="body">{t("Token.success")}</Typography>
    </div>
  </div>
);

export interface VerifiedSectionProps {
  t: (key: string, options?: any) => string;
  organization: Organization;
}

export const VerifiedSection: React.FC<VerifiedSectionProps> = ({
  t,
  organization,
}) => (
  <div className="general">
    <div className="success">
      <Typography className="header header-with-icon">
        {t("Token.verified")}
        <CheckCircleIcon className="icon" />
      </Typography>
    </div>
    <div className="multiple-explainers">
      <Typography className="body">
        {t("Token.verifiedExplainer1", { organization: organization.name })}
      </Typography>
      <Typography className="body">
        {t("Token.verifiedExplainer2", { organization: organization.name })}
      </Typography>
      <Typography className="body">{t("Token.verifiedExplainer3")}</Typography>
    </div>
  </div>
);
