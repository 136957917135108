import React, { useState } from "react";
import DecisionListItem from "./DecisionListItem";
import UpdateInstructionsModal from "./UpdateInstructionsModal"; // Import the modal component
import {
  IframePath,
  OS,
  checkOS,
  detectWindowsVersion,
  supportedIOSes,
  supportedMobileOSes,
} from "../../../System/System";
import { useClientApiContext } from "../../../Extension/ClientApiContext";

interface StatusDecisionListItemProps {
  navigator: any;
  browser: any;
  message: string;
  buttonText: string;
  showModal: boolean;
  blocking: boolean;
  timeTillBlocked?: number;
  path?: IframePath;
}

const StatusDecisionListItem: React.FC<StatusDecisionListItemProps> = ({
  navigator,
  browser,
  message,
  buttonText,
  showModal,
  blocking,
  timeTillBlocked,
  path,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const os: OS | null = checkOS(navigator) ?? null;
  const version: string | null = detectWindowsVersion();
  const { clientApi } = useClientApiContext();

  const handleActionClick = () => {
    if (showModal && os && !supportedMobileOSes.includes(os as OS)) {
      setIsModalOpen(true);
    } else {
      if (os === "Android") {
        const redirectUrl = new URL(
          "/token-mobile",
          process.env.REACT_APP_MOBILE_BASEURL
        );
        window.location.assign(redirectUrl.href);
      } else if (supportedIOSes.includes(os as OS)) {
        window.location.assign("xfaapp://");
      } else {
        clientApi.openExtension();
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <DecisionListItem
        message={message}
        buttonText={buttonText}
        blocking={blocking}
        onActionClick={handleActionClick}
        timeTillBlockedInHours={timeTillBlocked}
      />
      {isModalOpen && path && (
        <UpdateInstructionsModal
          platform={os}
          open={isModalOpen}
          handleClose={handleCloseModal}
          path={path}
          browser={browser}
          version={version}
        />
      )}
    </>
  );
};

export default StatusDecisionListItem;
