import { useCallback } from "react";
import {
  DeviceApi,
  Transaction,
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
} from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";

// eslint-disable-next-line camelcase
const action = _token__transactionId__post_request.action;

export const useMergeShadows = () => {
  return useCallback(async (transaction: Transaction) => {
    const deviceClient = new DeviceApi(deviceApiConfig);
    deviceClient.request.config.WITH_CREDENTIALS = true;
    deviceClient.request.config.CREDENTIALS = "include";

    try {
      await deviceClient.default.postToken1(transaction.transactionId, {
        action: action.MERGE_SHADOWS,
      });
      console.log("Shadows merged successfully.");
    } catch (error) {
      console.error("Error merging shadows", error);
    }
  }, []);
};
