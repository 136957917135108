/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _affiliated_get_200_response } from '../models/_affiliated_get_200_response';
import type { _authenticator_get_cred_id_get_200_response } from '../models/_authenticator_get_cred_id_get_200_response';
import type { AuthenticatorCreateRequest } from '../models/AuthenticatorCreateRequest';
import type { AuthenticatorCreateResponse } from '../models/AuthenticatorCreateResponse';
import type { AuthenticatorGetRequest } from '../models/AuthenticatorGetRequest';
import type { AuthenticatorGetResponse } from '../models/AuthenticatorGetResponse';
import type { Transaction } from '../models/Transaction';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any OK
     * @throws ApiError
     */
    public getInstalled(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/installed',
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public postUpdate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/update',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public postInvitation(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/invitation',
            errors: {
                501: `Not implemented`,
            },
        });
    }
    /**
     * @param organizationId
     * @returns _affiliated_get_200_response OK
     * @throws ApiError
     */
    public getAffiliated(
        organizationId: string,
    ): CancelablePromise<_affiliated_get_200_response> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/affiliated',
            query: {
                'organization_id': organizationId,
            },
        });
    }
    /**
     * @param transactionId
     * @returns any OK
     * @throws ApiError
     */
    public postJoinOrganization(
        transactionId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/join-organization',
            query: {
                'transaction_id': transactionId,
            },
        });
    }
    /**
     * @param transactionId
     * @returns Transaction OK
     * @throws ApiError
     */
    public putCompleteTransaction(
        transactionId: string,
    ): CancelablePromise<Transaction> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/complete-transaction',
            query: {
                'transaction_id': transactionId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns AuthenticatorCreateResponse OK
     * @throws ApiError
     */
    public postAuthenticatorCreate(
        requestBody?: AuthenticatorCreateRequest,
    ): CancelablePromise<AuthenticatorCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/authenticator-create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AuthenticatorGetResponse OK
     * @throws ApiError
     */
    public postAuthenticatorGet(
        requestBody?: AuthenticatorGetRequest,
    ): CancelablePromise<AuthenticatorGetResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/authenticator-get',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns _authenticator_get_cred_id_get_200_response OK
     * @throws ApiError
     */
    public getAuthenticatorGetCredId(): CancelablePromise<_authenticator_get_cred_id_get_200_response> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/authenticator-get-cred-id',
        });
    }
}
