import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { guideUrlConfig } from "../../../Config";
import { IframeBrowser, IframeOS, IframePath } from "../../../System/System";

interface IframeProps {
  path: IframePath;
  os?: IframeOS;
  browser?: IframeBrowser;
  version?: string;
}

export const GuidesGenerator: React.FC<IframeProps> = ({
  path,
  os,
  browser,
  version,
}) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const iframeRef = useRef<HTMLIFrameElement>(null);

  let url = `${guideUrlConfig.BASE}/${path}`;

  const queryParams = new URLSearchParams();
  if (os) queryParams.append("os", os);
  if (version) queryParams.append("version", version);
  if (browser) queryParams.append("browser", browser);
  if (currentLang) queryParams.append("lang", currentLang);

  url += `?${queryParams.toString()}`;

  return (
    <iframe
      ref={iframeRef}
      title="XFA Instructions"
      src={url}
      style={{
        width: "450px",
        height: "100%",
        border: "none",
      }}
    ></iframe>
  );
};
