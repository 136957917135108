/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DecisionMfa_credentials } from './DecisionMfa_credentials';
export type DecisionMfa = {
    /**
     * status of decision
     */
    status: DecisionMfa.status;
    error?: string;
    /**
     * will MFA be auto approved
     */
    autoApprove?: boolean;
    /**
     * is MFA auto approved
     */
    autoApproved?: boolean;
    credentials?: DecisionMfa_credentials;
};
export namespace DecisionMfa {
    /**
     * status of decision
     */
    export enum status {
        OK = 'OK',
        WARNING = 'WARNING',
        BLOCKING = 'BLOCKING',
    }
}

