import React from "react";
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import "./TopBar.css";

interface TopBarProps {
  transaction: any;
  signIn: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ transaction, signIn }) => {
  const { t } = useTranslation();
  return (
    <div className="top-bar">
      <div className="bar-content">
        {signIn ? (
          <>
            <Typography className="signing-into">
              {transaction && transaction.application
                ? t("Token.signingInto") +
                  " " +
                  (transaction.application.name === ""
                    ? t("Token.application")
                    : transaction.application.name)
                : transaction && transaction.organization
                ? t("Token.verifyingDeviceFor") +
                  " " +
                  transaction.organization.name
                : t("Token.signingIntoDemo")}
            </Typography>
            {transaction &&
            transaction.application &&
            transaction.application.iconUrl ? (
              <img
                src={transaction.application.iconUrl}
                alt="Logo"
                className="logo"
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
        )}
      </div>
    </div>
  );
};

export default TopBar;
