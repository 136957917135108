import React from "react";
import Decisions from "./Decisions/Decisions";
import { Transaction } from "../../API/XFA_DEVICE_API";
import Typography from "@mui/material/Typography";

export interface NokSectionProps {
  t: (key: string, options?: any) => string;
  browser: any;
  navigator: any;
  transaction: Transaction | undefined;
  endTransaction: (transaction: Transaction) => void;
  refreshTransactionStatus: () => void;
  refreshingTransaction: boolean;
}

export const NokSection: React.FC<NokSectionProps> = ({
  t,
  browser,
  navigator,
  transaction,
  endTransaction,
  refreshTransactionStatus,
  refreshingTransaction,
}) => {
  return (
    <div className="general">
      <Typography className="header">{t("Token.nok.title")}</Typography>
      {transaction?.application ? (
        <Typography className="body1">
          {t("Token.nok.subtitleApplication")}
        </Typography>
      ) : (
        transaction?.verification && (
          <Typography className="body1">
            {t("Token.nok.subtitleVerification", {
              organization: transaction.organization?.name,
            })}
          </Typography>
        )
      )}
      <Decisions
        browser={browser}
        navigator={navigator}
        transaction={transaction!}
        endTransaction={endTransaction}
        refreshTransactionStatus={refreshTransactionStatus}
        refreshingTransaction={refreshingTransaction}
      />
    </div>
  );
};
