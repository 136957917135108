import { NavigateFunction } from "react-router-dom";

export const goToTokenTransaction = (navigate: NavigateFunction) => {
  navigate("/token");
};

export const goToMfa = (navigate: NavigateFunction, transactionId: string) => {
  navigate(`/mfa?transactionId=${transactionId}`);
};

export const goToInstallationFromTokenTransaction = (
  navigate: NavigateFunction,
  transactionId: string
) => {
  navigate(`/installation?transactionId=${transactionId}`);
};
