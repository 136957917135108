/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { _affiliations_delete_request } from "../models/_affiliations_delete_request";
import type { _affiliations_post_request } from "../models/_affiliations_post_request";
import type { _push_register_post_request } from "../models/_push_register_post_request";
import type { _test_token_post_request } from "../models/_test_token_post_request";
import type { _token__transactionId__post_request } from "../models/_token__transactionId__post_request";
import type { _token_post_request } from "../models/_token_post_request";
import type { AffiliationList } from "../models/AffiliationList";
import type { LoginBeginResponse } from "../models/LoginBeginResponse";
import type { LoginFinishRequest } from "../models/LoginFinishRequest";
import type { MessageList } from "../models/MessageList";
import type { Posture } from "../models/Posture";
import type { PublicKeyList } from "../models/PublicKeyList";
import type { RegisterBeginResponse } from "../models/RegisterBeginResponse";
import type { RegisterFinishRequest } from "../models/RegisterFinishRequest";
import type { TestTokenResponse } from "../models/TestTokenResponse";
import type { TokenResponse } from "../models/TokenResponse";
import type { Transaction } from "../models/Transaction";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class DefaultService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * CORS headers
   * @param proxy
   * @returns any CORS headers available
   * @throws ApiError
   */
  public options(proxy: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "OPTIONS",
      url: "/{proxy+}",
      path: {
        "proxy+": proxy,
      },
    });
  }
  /**
   * Register statistics
   * @returns TokenResponse Device was successfully registered
   * @throws ApiError
   */
  public postStatsRegister(): CancelablePromise<TokenResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/stats/register",
    });
  }
  /**
   * Expose OpenAPI spec
   * @returns string Returns OpenAPI spec in YAML spec
   * @throws ApiError
   */
  public getOpenapi(): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/openapi",
    });
  }
  /**
   * Create transaction to gain token
   * @param requestBody
   * @param deviceToken Optional cookie for device identification
   * @returns Transaction Return transaction object (includes transactionId)
   * @throws ApiError
   */
  public postToken(
    requestBody: _token_post_request,
    deviceToken?: string
  ): CancelablePromise<Transaction> {
    return this.httpRequest.request({
      method: "POST",
      url: "/token",
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Verify device
   * @param deviceId Device ID to verify
   * @param organizationId Organization ID of organization to verify device for
   * @returns void
   * @throws ApiError
   */
  public getVerify(
    deviceId: string | undefined,
    organizationId: string,
    policyId: string | undefined
  ): CancelablePromise<Transaction> {
    return this.httpRequest.request({
      method: "GET",
      url: "/verify",
      query: {
        deviceId: deviceId,
        organizationId: organizationId,
        policyId: policyId,
      },
      errors: {
        404: `Device not found`,
      },
    });
  }
  /**
   * Confirm MFA for transaction
   * @param transactionId Transaction ID of token request
   * @param challenge Challenge for MFA
   * @returns void
   * @throws ApiError
   */
  public getConfirmMfa(
    transactionId: string,
    challenge: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "GET",
      url: "/confirm-mfa/{transactionId}",
      path: {
        transactionId: transactionId,
      },
      query: {
        challenge: challenge,
      },
    });
  }
  /**
   * Returns transaction object related to a token request
   * @param transactionId Transaction ID of token request
   * @returns Transaction Returns transaction object of token request
   * @throws ApiError
   */
  public getToken(transactionId: string): CancelablePromise<Transaction> {
    return this.httpRequest.request({
      method: "GET",
      url: "/token/{transactionId}",
      path: {
        transactionId: transactionId,
      },
    });
  }
  /**
   * Perform action through transaction
   * @param transactionId Transaction ID of token request
   * @param requestBody
   * @param token Secret token of device
   * @param deviceToken Optional cookie for device identification
   * @param challenge Challenge for MFA
   * @param email Force to send an email instead of message to trusted devices
   * @returns TokenResponse Successfully executed action
   * @throws ApiError
   */
  public postToken1(
    transactionId: string,
    requestBody: _token__transactionId__post_request,
    token?: string,
    deviceToken?: string,
    challenge?: string,
    email?: boolean
  ): CancelablePromise<TokenResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/token/{transactionId}",
      path: {
        transactionId: transactionId,
      },
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      headers: {
        token: token,
      },
      query: {
        challenge: challenge,
        email: email,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Update transaction with posture or requests
   * @param transactionId Transaction ID of token request
   * @param token Secret token of device
   * @param deviceToken Optional cookie for device identification
   * @param requestBody
   * @returns Transaction Returns updated transaction object of token request
   * @throws ApiError
   */
  public putToken(
    transactionId: string,
    token?: string,
    deviceToken?: string,
    requestBody?: Posture
  ): CancelablePromise<Transaction> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/token/{transactionId}",
      path: {
        transactionId: transactionId,
      },
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      headers: {
        token: token,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Get valid token with Test API Key
   * @param requestBody
   * @param testkey Secret testkey of application
   * @returns TestTokenResponse Returns token
   * @throws ApiError
   */
  public postTestToken(
    requestBody: _test_token_post_request,
    testkey?: string
  ): CancelablePromise<TestTokenResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/test-token",
      headers: {
        testkey: testkey,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Get Metadata for SP
   * @param applicationId Application ID
   * @returns string Returns metadata for SP
   * @throws ApiError
   */
  public getSaml2SpMetadata(applicationId?: string): CancelablePromise<string> {
    return this.httpRequest.request({
      method: "GET",
      url: "/saml2/sp/metadata",
      query: {
        applicationId: applicationId,
      },
      errors: {
        404: `Not found`,
      },
    });
  }
  /**
   * Start IDP SSO request
   * @param applicationId Application ID
   * @param samlRequest SAML Request
   * @param relayState RelayState
   * @param deviceToken Optional cookie for device identification
   * @returns void
   * @throws ApiError
   */
  public getSaml2Idp(
    applicationId?: string,
    samlRequest?: string,
    relayState?: string,
    deviceToken?: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "GET",
      url: "/saml2/idp",
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      query: {
        applicationId: applicationId,
        SAMLRequest: samlRequest,
        RelayState: relayState,
      },
      errors: {
        302: `Forwards to identity provider`,
        400: `Failed`,
        404: `Not found`,
      },
    });
  }
  /**
   * Start IDP SSO request
   * @param applicationId Application ID
   * @returns void
   * @throws ApiError
   */
  public postSaml2Idp(applicationId?: string): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "POST",
      url: "/saml2/idp",
      query: {
        applicationId: applicationId,
      },
      errors: {
        302: `Forwards to identity provider`,
        400: `Failed`,
        404: `Not found`,
      },
    });
  }
  /**
   * Process consume response
   * @param samlRequest SAML Request
   * @param relayState RelayState
   * @returns void
   * @throws ApiError
   */
  public getSaml2Consume(
    samlRequest?: string,
    relayState?: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "GET",
      url: "/saml2/consume",
      query: {
        SAMLRequest: samlRequest,
        RelayState: relayState,
      },
      errors: {
        302: `Forwards to service provider`,
      },
    });
  }
  /**
   * Process consume response
   * @returns any Forwards to service provider with self-submitting form
   * @throws ApiError
   */
  public postSaml2Consume(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/saml2/consume",
    });
  }
  /**
   * OAuth2 authorize endpoint
   * @param clientId Client ID
   * @returns any Completes OAuth2 request
   * @throws ApiError
   */
  public getOauth2Authorize(clientId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/oauth2/authorize",
      query: {
        client_id: clientId,
      },
    });
  }
  /**
   * OAuth2 authorize endpoint
   * @returns any Completes OAuth2 request
   * @throws ApiError
   */
  public postOauth2Authorize(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/oauth2/authorize",
    });
  }
  /**
   * OAuth2 token endpoint
   * @returns any Completes OAuth2 request
   * @throws ApiError
   */
  public postOauth2Token(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/oauth2/token",
    });
  }
  /**
   * OAuth2 userinfo endpoint
   * @returns any Completes OAuth2 request
   * @throws ApiError
   */
  public getOauth2Userinfo(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/oauth2/userinfo",
    });
  }
  /**
   * Submit statistics
   * @param token Secret token of device
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public postStatsSubmit(
    token: string,
    requestBody: Posture
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "POST",
      url: "/stats/submit",
      headers: {
        token: token,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Bad token`,
      },
    });
  }
  /**
   * refresh device token
   * @param token Secret token of device
   * @returns TokenResponse Device was successfully registered
   * @throws ApiError
   */
  public postStatsRefresh(token: string): CancelablePromise<TokenResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/stats/refresh",
      headers: {
        token: token,
      },
      errors: {
        401: `Bad token`,
      },
    });
  }
  /**
   * Get public keys to validate device tokens
   * @param applicationId Application ID of OAuth2 request
   * @returns any Device successfully registered for push notifications
   * @throws ApiError
   */
  public getWellKnownJwks(applicationId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/{applicationId}/.well-known/jwks",
      path: {
        applicationId: applicationId,
      },
    });
  }
  /**
   * Get OpenID Connect configuration
   * @param applicationId Application ID of OAuth2 request
   * @returns any Device successfully registered for push notifications
   * @throws ApiError
   */
  public getOauth2WellKnownOidcConfiguration(
    applicationId: string
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/{applicationId}/oauth2/.well-known/oidc-configuration",
      path: {
        applicationId: applicationId,
      },
    });
  }
  /**
   * OAuth2 redirect-endpoint for GET
   * @param applicationId Application ID of OAuth2 request
   * @param deviceToken Optional cookie for device identification
   * @returns any Completes OAuth2 request
   * @throws ApiError
   */
  public getOauth2Redirect(
    applicationId: string,
    deviceToken?: string
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/{applicationId}/oauth2/redirect",
      path: {
        applicationId: applicationId,
      },
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
    });
  }
  /**
   * OAuth2 redirect-endpoint for GET
   * @param applicationId Application ID of OAuth2 request
   * @returns any Completes OAuth2 request
   * @throws ApiError
   */
  public postOauth2Redirect(applicationId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/{applicationId}/oauth2/redirect",
      path: {
        applicationId: applicationId,
      },
    });
  }
  /**
   * OAuth2 endpoint for callbacks
   * @param transactionId TransactionId
   * @returns any Callback succeeded
   * @throws ApiError
   */
  public getOauth2Callback(transactionId?: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/oauth2/callback",
      query: {
        transactionId: transactionId,
      },
    });
  }
  /**
   * register device to receive push notifications
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public postPushRegister(
    requestBody: _push_register_post_request
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "POST",
      url: "/push/register",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * get messages for device
   * @param token Secret token of device
   * @returns MessageList Available MFA messages for device
   * @throws ApiError
   */
  public getMessage(token: string): CancelablePromise<MessageList> {
    return this.httpRequest.request({
      method: "GET",
      url: "/message",
      headers: {
        token: token,
      },
    });
  }
  /**
   * delete message
   * @param token Secret token of device
   * @param messageId messageId
   * @returns void
   * @throws ApiError
   */
  public deleteMessage(
    token: string,
    messageId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/message/{messageId}",
      path: {
        messageId: messageId,
      },
      headers: {
        token: token,
      },
      errors: {
        401: `Bad token or deviceId not matching message`,
      },
    });
  }
  /**
   * delete an affiliation for a device to an organization
   * @param requestBody
   * @returns TokenResponse Affiliation successfully deleted for device, and returns new device token
   * @throws ApiError
   */
  public deleteAffiliations(
    requestBody: _affiliations_delete_request
  ): CancelablePromise<TokenResponse> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/affiliations",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * get affiliations for device
   * @param token Secret token of device
   * @returns AffiliationList retrieve list of affiliations for device
   * @throws ApiError
   */
  public getAffiliations(token: string): CancelablePromise<AffiliationList> {
    return this.httpRequest.request({
      method: "GET",
      url: "/affiliations",
      headers: {
        token: token,
      },
    });
  }
  /**
   * create an affiliation for a device to an organization
   * @param requestBody
   * @returns TokenResponse Affiliation successfully created for device, and returns new device token
   * @throws ApiError
   */
  public postAffiliations(
    requestBody: _affiliations_post_request
  ): CancelablePromise<TokenResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/affiliations",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * Get public keys to validate device tokens
   * @returns PublicKeyList Device successfully registered for push notifications
   * @throws ApiError
   */
  public getKeys(): CancelablePromise<PublicKeyList> {
    return this.httpRequest.request({
      method: "GET",
      url: "/keys",
    });
  }
  /**
   * @param transactionId Transaction ID
   * @param token Secret token of device
   * @param deviceToken Optional cookie for device identification
   * @returns LoginBeginResponse MFA login started
   * @throws ApiError
   */
  public postLoginBegin(
    transactionId: string,
    token?: string,
    deviceToken?: string
  ): CancelablePromise<LoginBeginResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/login/begin",
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      headers: {
        token: token,
      },
      query: {
        transactionId: transactionId,
      },
    });
  }
  /**
   * @param sessionId Session ID
   * @param transactionId Transaction ID
   * @param requestBody
   * @param token Secret token of device
   * @param deviceToken Optional cookie for device identification
   * @returns void
   * @throws ApiError
   */
  public postLoginFinish(
    sessionId: string,
    transactionId: string,
    requestBody: LoginFinishRequest,
    token?: string,
    deviceToken?: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "POST",
      url: "/login/finish",
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      headers: {
        token: token,
      },
      query: {
        sessionId: sessionId,
        transactionId: transactionId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param transactionId Transaction ID
   * @param token Secret token of device
   * @param deviceToken Optional cookie for device identification
   * @returns RegisterBeginResponse MFA registration started
   * @throws ApiError
   */
  public postRegisterBegin(
    transactionId: string,
    token?: string,
    deviceToken?: string
  ): CancelablePromise<RegisterBeginResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/register/begin",
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      headers: {
        token: token,
      },
      query: {
        transactionId: transactionId,
      },
    });
  }
  /**
   * @param transactionId Transaction ID
   * @param sessionId Session ID
   * @param requestBody
   * @param token Secret token of device
   * @param deviceToken Optional cookie for device identification
   * @returns void
   * @throws ApiError
   */
  public postRegisterFinish(
    transactionId: string,
    sessionId: string,
    requestBody: RegisterFinishRequest,
    token?: string,
    deviceToken?: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: "POST",
      url: "/register/finish",
      cookies: {
        DEVICE_TOKEN: deviceToken,
      },
      headers: {
        token: token,
      },
      query: {
        transactionId: transactionId,
        sessionId: sessionId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
