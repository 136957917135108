import { Transaction } from "../../API/XFA_DEVICE_API";
import { TokenStatus } from "../../System/System";

export const checkAffiliationConditions = (
  checkDeviceAffiliatedWithOrganization: (
    organizationId: string,
    callback: (reply: any) => any
  ) => void,
  isInstalled: boolean | undefined,
  devicePreviouslyAffiliated: boolean | undefined,
  transaction: Transaction | undefined,
  setDevicePreviouslyAffiliated: (affiliated: boolean) => void,
  setTokenStatus: (status: TokenStatus) => void,
  setError: (message: string) => void,
  t: (key: string) => string
) => {
  //only do it once (while devicePreviouslyAffiliated === undefined)
  if (devicePreviouslyAffiliated !== undefined) {
    return;
  }

  //make sure transaction is present
  if (!transaction) {
    return;
  }

  //only handle when transaction still pending
  if (transaction.status !== "PENDING") {
    return;
  }
  //only check when extension is installed
  if (!isInstalled) {
    return;
  }

  //only check if there is a requirement to be affiliated
  if (
    transaction?.decisions?.affiliated &&
    transaction?.organization?.organizationId
  ) {
    try {
      checkDeviceAffiliatedWithOrganization(
        transaction.organization.organizationId,
        (affiliated) => {
          setDevicePreviouslyAffiliated(affiliated === true);
        }
      );
    } catch (error) {
      console.error("Error checking affiliation", error);
      setDevicePreviouslyAffiliated(false);
      setTokenStatus("Error");
      setError(t("Token.errors.affiliation"));
    }
  }
};
