import { useEffect, useState } from "react";
import {
  OS,
  Browser,
  checkOS,
  checkBrowser,
  supportedDesktopOSes,
  supportedBrowsers,
  supportedMobileOSes,
} from "../../System/System";

export const useDeviceSupport = (
  navigator: any,
  isInstalled: boolean | undefined
) => {
  const [os, setOS] = useState<undefined | OS>(undefined);
  const [browser, setBrowser] = useState<undefined | Browser>(undefined);
  const [supported, setSupported] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const detectedOS = checkOS(navigator);
    const detectedBrowser = checkBrowser(navigator);
    setOS(detectedOS);
    setBrowser(detectedBrowser);

    if (
      detectedOS &&
      supportedDesktopOSes.includes(detectedOS) &&
      detectedBrowser &&
      supportedBrowsers.includes(detectedBrowser)
    ) {
      setSupported(true);
    } else if (detectedOS && supportedMobileOSes.includes(detectedOS)) {
      setSupported(true);
    } else {
      // Check if extension is installed for experimental systems
      if (
        (detectedOS === "UNIX" && detectedBrowser === "Firefox") ||
        (detectedOS === "macOS" && detectedBrowser === "Safari")
      ) {
        if (isInstalled !== undefined) {
          setSupported(isInstalled);
        }
      } else {
        setSupported(false);
      }
    }
  }, [navigator, isInstalled]);

  return { os, browser, supported };
};
