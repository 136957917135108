/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Application } from './Application';
import type { Decisions } from './Decisions';
import type { Organization } from './Organization';
import type { User } from './User';
import type { Verification } from './Verification';
export type Transaction = {
    /**
     * The transaction ID
     */
    transactionId: string;
    user: User;
    application?: Application;
    organization?: Organization;
    verification?: Verification;
    decisions?: Decisions;
    /**
     * Status of transaction
     */
    status: Transaction.status;
    /**
     * TTL of transaction (expiration time)
     */
    ttl: number;
    /**
     * (deprecated, see suggestManualRedirect) should ask to manually go back to the application on iOS
     */
    suggestManualRedirectOnIOS?: boolean;
    /**
     * should ask to manually go back to the application
     */
    suggestManualRedirect?: boolean;
    /**
     * Detected service provider name to change behaviour
     */
    detectedServiceProviderName?: string;
    /**
     * RedirectURL to use when transaction completed
     */
    redirectUrl?: string;
    /**
     * Resulting access token of transaction
     */
    access_token?: string;
    /**
     * Device ID provided by completing client
     */
    device_id?: string;
    /**
     * Device ID from browser that created transaction
     */
    device_id_from_browser?: string;
};
export namespace Transaction {
    /**
     * Status of transaction
     */
    export enum status {
        PENDING = 'PENDING',
        SKIPPED = 'SKIPPED',
        UNSUPPORTED = 'UNSUPPORTED',
        COMPLETED = 'COMPLETED',
        FILTERED = 'FILTERED',
    }
}

