/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DecisionEnabled = {
    /**
     * status of decision
     */
    status: DecisionEnabled.status;
};
export namespace DecisionEnabled {
    /**
     * status of decision
     */
    export enum status {
        OK = 'OK',
        WARNING = 'WARNING',
        BLOCKING = 'BLOCKING',
    }
}

