import { Transaction } from "../../API/XFA_DEVICE_API";
import { checkOS, OS, TokenStatus } from "../../System/System";

export const handleCompletedTransaction = (
  transaction: Transaction,
  mergeShadows: (transaction: Transaction) => Promise<void>,
  setTokenStatus: (status: TokenStatus) => void,
  endTransaction: (transaction: Transaction, os: OS) => void
) => {
  const os = checkOS(window.navigator);
  if (transaction && transaction.status === "COMPLETED") {
    // attempt merge shadows if deviceID's of browser and client are different
    if (
      transaction.device_id &&
      transaction.device_id_from_browser &&
      transaction.device_id !== transaction.device_id_from_browser
    ) {
      try {
        mergeShadows(transaction);
      } catch (error) {
        console.error("Error merging shadows", error);
      }
    }

    // Check for any warnings or blocking statuses, if none, end the transaction
    const hasBlockingOrWarningStatus = Object.keys(transaction.decisions!).some(
      (key) => {
        return (
          key !== "mfa" &&
          // @ts-ignore
          (transaction.decisions![key].status === "WARNING" ||
            // @ts-ignore
            transaction.decisions![key].status === "BLOCKING")
        );
      }
    );

    if (!hasBlockingOrWarningStatus) {
      setTokenStatus("OK");
      endTransaction(transaction, os!);
    } else {
      setTokenStatus("NOK");
    }
  }
};
