import Button from "@mui/material/Button";
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useTranslation } from "react-i18next";

// Extend the Window interface to include Intercom typings
// This step is optional but recommended for type safety
declare global {
  interface Window {
    Intercom: (...args: any[]) => void;
    intercomSettings?: any;
    attachEvent?: (event: string, listener: () => void) => void;
  }
}

// Set your APP_ID
export const APP_ID: string = "qana8cn9";

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
export const loadIntercom: () => void = () => {
  // prettier-ignore
  (function() {
    const w: Window = window;
    const ic: any = w.Intercom;
    if (typeof ic === "function") {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i: any = function() { i.c(arguments); };
      i.q = [];
      i.c = function(args: IArguments) { i.q.push(args); };
      w.Intercom = i;
      const l: () => void = function() {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.Intercom.io/widget/' + APP_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode?.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

// Initializes Intercom
export const bootIntercom: (options?: { [key: string]: any }) => void = (
  options = {}
) => {
  window &&
    window.Intercom &&
    window.Intercom("boot", {
      app_id: APP_ID,
      ...options,
      custom_launcher_selector: "#intercom",
      hide_default_launcher: true,
      vertical_padding: 80,
    });
};

export const updateIntercom: () => void = () => {
  window && window.Intercom && window.Intercom("update");
};

export const updateIntercomProps = (
  email: string | undefined,
  organizationName: string | undefined,
  transactionId: string | undefined
) => {
  window.Intercom("update", {
    customAttributes: {
      email,
      organization_name: organizationName,
      transaction_id: transactionId,
    },
  });
};

// Define a type for the component's props
type IntercomProviderProps = {
  children: ReactNode;
};

export const IntercomOverlay = ({ children }: IntercomProviderProps) => {
  return (
    <>
      {children}
      <span style={{ right: 32, bottom: 24, position: "fixed", zIndex: 1000 }}>
        <IntercomInlineButton />
      </span>
    </>
  );
};

export const IntercomInlineButton = () => {
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      loadIntercom();
      bootIntercom();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateIntercom();
    }
  }, [location]); // This effect runs when the location changes.

  return (
    <Button
      variant="outlined"
      id="intercom"
      size="medium"
      style={{
        fontWeight: 600,
        textTransform: "none",
        backgroundColor: "white",
      }}
    >
      <SupportAgentIcon sx={{ mr: 1 }} />
      {t("support")}
    </Button>
  );
};
