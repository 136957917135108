import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../General/general.css";

interface ClientMissingSectionProps {
  verifyDevice: () => void;
}

export const WrongDeviceSection: React.FC<ClientMissingSectionProps> = ({
  verifyDevice,
}) => {
  const { t } = useTranslation();

  return (
    <div className="general">
      <Typography className="header" style={{ marginBottom: "12px" }}>
        {t("verification.wrongDeviceTitle")}
      </Typography>
      <Typography variant="body1" className="information">
        {t("verification.wrongDeviceDescription")}
      </Typography>
      <Typography variant="body1" className="information">
        {t("verification.wrongDeviceDescription2")}
      </Typography>

      <Typography className="extra-information">
        <Button
          size="large"
          variant="contained"
          className="black-button"
          style={{ marginTop: "32px" }}
          onClick={verifyDevice}
        >
          <Typography className={"refresh-text"}>
            {t("verification.verifyThisDevice")}
          </Typography>
        </Button>
      </Typography>
      <Typography variant="body1" className="information">
        {t("verification.wrongDeviceDescription3")}
      </Typography>
    </div>
  );
};
