import React from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  StepContent,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ChromeWebStore from "./images/chrome-web-store.png";
import ChromeConfirm from "./images/chromeConfirm.png";
import ChromeExtenionImage from "./images/chrome-extension.png";
import FirefoxExtensionImage from "./images/firefox-extension.png";
import EdgeExtensionImage from "./images/edge-extension.png";

import EdgeWebStore from "./images/edge-web-store.png";
import EdgeConfirm from "./images/edgeConfirm.png";
import AppIcon from "../../../images/General_XFA_app_icon.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import FirefoxAddonStore from "./images/firefox-addons.png";
import FirefoxConfirm from "./images/firefoxConfirm.png";
import {
  chromeExtensionLink,
  edgeExtensionLink,
  mozillaExtensionLink,
} from "../../System/System";
import "../../General/general.css";
import { useClientApiContext } from "../../Extension/ClientApiContext";

interface InstallInstructionsSectionProps {
  invited: boolean | undefined;
  browser: any;
  navigator: any;
  isPrivate: boolean | undefined;
}

const InstallInstructionsSection: React.FC<InstallInstructionsSectionProps> = ({
  invited,
  browser,
  navigator,
  isPrivate,
}) => {
  const [isPrivateDialogOpen, setIsPrivateDialogOpen] = React.useState(false);
  type BrowserType = "Chrome" | "Firefox" | "Edge";

  const browserDetails = {
    Chrome: {
      stepImages: {
        step2: ChromeWebStore,
        step3: ChromeConfirm,
        step2Private: ChromeExtenionImage,
      },
      storeLink: chromeExtensionLink,
      extensionsLink: "chrome://extensions",
    },
    Firefox: {
      stepImages: {
        step2: FirefoxAddonStore,
        step3: FirefoxConfirm,
        step2Private: FirefoxExtensionImage,
      },
      storeLink: mozillaExtensionLink,
      extensionsLink: "about:addons",
    },
    Edge: {
      stepImages: {
        step2: EdgeWebStore,
        step3: EdgeConfirm,
        step2Private: EdgeExtensionImage,
      },
      storeLink: edgeExtensionLink,
      extensionsLink: "edge://extensions",
    },
  };
  const { t } = useTranslation();
  const { stepImages, storeLink, extensionsLink } =
    browserDetails[browser as BrowserType];
  const steps = [
    {
      label: t(`Installation.step1`, { browser: browser }),
      image: stepImages.step2,
    },
    { label: t(`Installation.Step2${browser}`), image: stepImages.step2 },
    { label: t(`Installation.Step3${browser}`), image: stepImages.step3 },
  ];
  const privateSteps = [
    {
      label: t(`Installation.step1Private`, { extensionsLink: extensionsLink }),
    },
    {
      label: t(`Installation.step2Private${browser}`),
      image: stepImages.step2Private,
    },
    {
      label: t(`Installation.step3Private${browser}`),
    },
  ];
  const { checking } = useClientApiContext();

  return (
    <>
      {!invited ? (
        <div>
          <Typography className="header">{t(`Installation.title`)}</Typography>
          <Typography className="body1">
            {t("Installation.description", { name: browser })}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography className="header">
            {t(`Installation.titleInvited`)}
          </Typography>
          <Typography className="body1">
            {t("Installation.description", { name: browser })}
          </Typography>
        </div>
      )}
      {isPrivate && (
        <>
          <h3 className="header">{t(`Installation.isPrivate`)}</h3>
          <div className="button-container" style={{ width: "100%" }}>
            <Button
              startIcon={
                <img className="smallIcon" src={AppIcon} alt="Ongoing" />
              }
              className="icon-button"
              onClick={() => setIsPrivateDialogOpen(true)}
              endIcon={<ChevronRightIcon />}
              style={{ width: "100%" }}
            >
              <Typography className="install-text" style={{ flex: 1 }}>
                {t("Installation.isPrivateButton")}
              </Typography>
            </Button>
          </div>
        </>
      )}
      <Stepper className="instructions" orientation="vertical">
        {steps.map((step, index) => (
          <Step
            sx={{
              ".MuiStepLabel-label": {
                color: "#4B5057 !important",
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "19px",
                marginLeft: "32px",
                letterSpacing: "0em",
              },
            }}
            key={index}
            active
          >
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <div className="stepContent">
                {index !== 0 ? (
                  <img
                    alt={step.label}
                    style={{ width: 300 }}
                    src={step.image}
                  />
                ) : (
                  <Button
                    variant="contained"
                    className="black-button"
                    onClick={() => {
                      window.open(storeLink, "_blank");
                    }}
                  >
                    {t(`Installation.step1Button`, { browser: browser })}
                  </Button>
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <div
        className="rechecking"
        style={{ visibility: checking ? "visible" : "hidden" }}
      >
        <CircularProgress className="indicator" />
        {t("Installation.recheckingExtension")}
      </div>

      <Dialog
        open={isPrivateDialogOpen}
        onClose={() => setIsPrivateDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>
          {t(`Installation.installationInstructionsPrivate`)}
          <IconButton
            aria-label="close"
            onClick={() => setIsPrivateDialogOpen(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper className="instructions" orientation="vertical">
            {privateSteps.map((step, index) => (
              <Step
                key={index}
                sx={{
                  ".MuiStepLabel-label": {
                    color: "#4B5057 !important",
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: "19px",
                    marginLeft: "32px",
                    letterSpacing: "0em",
                  },
                }}
                active
              >
                <StepLabel>{step.label}</StepLabel>
                {step.image && (
                  <StepContent>
                    <div className="stepContent">
                      <img
                        alt={step.label}
                        style={{ width: 300 }}
                        src={step.image}
                      />
                    </div>
                  </StepContent>
                )}
              </Step>
            ))}
          </Stepper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InstallInstructionsSection;
