/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DecisionPasswordmanager = {
    /**
     * status of decision
     */
    status: DecisionPasswordmanager.status;
};
export namespace DecisionPasswordmanager {
    /**
     * status of decision
     */
    export enum status {
        OK = 'OK',
        WARNING = 'WARNING',
        BLOCKING = 'BLOCKING',
    }
}

