/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Authentication = {
    /**
     * Authentication is active on the device
     */
    active?: boolean;
    /**
     * Biometrics is active on the device
     */
    biometrics?: Authentication.biometrics;
};
export namespace Authentication {
    /**
     * Biometrics is active on the device
     */
    export enum biometrics {
        NONE = 'none',
        TOUCH = 'touch',
        FACE = 'face',
    }
}

