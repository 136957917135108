import React, { useEffect } from "react";

type FaviconProps = {
  title: string;
  url: string;
};

const SetFavicon: React.FC<FaviconProps> = ({ title, url }) => {
  useEffect(() => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const size = 32;
      canvas.width = size;
      canvas.height = size;

      ctx?.drawImage(img, 0, 0, size, size);

      const resizedFavicon = canvas.toDataURL("image/png");

      document
        .querySelectorAll("link[rel*='icon']")
        .forEach((el) => el.remove());

      const link = document.createElement("link");
      link.type = "image/png";
      link.rel = "icon";
      link.href = resizedFavicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    };
  }, [url]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

export default SetFavicon;
