import React from "react";
import "./Installation.css";
import { useTranslation } from "react-i18next";

import { CircularProgress, Typography } from "@mui/material";

import {
  checkOS,
  checkBrowser,
  OS,
  Browser,
  supportedDesktopOSes,
  supportedMobileOSes,
  supportedBrowsers,
  playStoreLink,
  appStoreLink,
  supportedIOSes,
} from "../System/System";

import { useSearchParams, useNavigate } from "react-router-dom";

import { setupDeferredDeepLinkForLatestInvitation } from "../Deeplinks/Deeplinks";

import { goToTokenTransaction } from "../Navigation/Navigation";

import useTransaction from "../Token/hooks/useTransaction";
import NotSupportedOSSection from "./sections/NotSupportedOSSection";
import NotSupportedBrowserSection from "./sections/NotSupportedBrowserSection";
import ExtensionInstalledSection from "./sections/ExtensionInstalledSection";
import InstallInstructionsSection from "./sections/InstallInstructionSection";
import { ConnectSection } from "./sections/ConnectSection";
import TopBar from "../General/TopBar";
import { detectIncognito } from "detectincognitojs";
import { useClientApiContext } from "../Extension/ClientApiContext";

export interface InstallationProps {
  navigator: any;
  browser: any;
}

const Installation: React.FC<InstallationProps> = (
  props: InstallationProps
) => {
  const { t } = useTranslation();

  const { transaction } = useTransaction(props.navigator);

  const navigate = useNavigate();

  // System information
  const [os, setOS] = React.useState<undefined | OS>(undefined);
  const [browser, setBrowser] = React.useState<undefined | Browser>(undefined);
  const [isPrivate, setIsPrivate] = React.useState<boolean | undefined>(
    undefined
  );
  const { checking, isClientAvailable } = useClientApiContext();

  React.useEffect(() => {
    setOS(checkOS(props.navigator));
    setBrowser(checkBrowser(props.navigator));
  }, [props.navigator]);

  //when the transaction completes, redirect to application and clear the transaction
  React.useEffect(() => {
    detectIncognito().then((result) => {
      setIsPrivate(result.isPrivate);
    });

    // for now only do this on mobile
    if (!os || !supportedMobileOSes.includes(os)) {
      return;
    }

    if (transaction?.status === "COMPLETED") {
      goToTokenTransaction(navigate);
    }
  }, [transaction?.status, navigate, os]);

  // Supported components?
  const [supportedOS, setSupportedOS] = React.useState<undefined | boolean>(
    undefined
  );

  const [supportedBrowser, setSupportedBrowser] = React.useState<
    undefined | boolean
  >(undefined);

  React.useEffect(() => {
    if (browser && os) {
      console.log(`detected ${browser} on ${os}`);
    }
    if (
      os &&
      (supportedDesktopOSes.includes(os) || supportedMobileOSes.includes(os))
    ) {
      setSupportedOS(true);
    } else {
      setSupportedOS(false);
    }

    if (
      (browser && supportedBrowsers.includes(browser)) ||
      (os && supportedMobileOSes.includes(os))
    ) {
      setSupportedBrowser(true);
    } else {
      setSupportedBrowser(false);
    }
  }, [browser, os]);

  // Supported combination?
  const [supported, setSupported] = React.useState<undefined | boolean>(
    undefined
  );

  React.useEffect(() => {
    setSupported(supportedBrowser && supportedOS);
  }, [supportedBrowser, supportedOS]);

  const [searchParams] = useSearchParams();
  const [invited, setInvited] = React.useState<boolean | undefined>(undefined);
  const [ongoingTransaction, setOngoingTransaction] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    // check if there is a token available in the querystring
    setInvited(searchParams.has("invited"));
    setOngoingTransaction(searchParams.has("transactionId"));
  }, [setOngoingTransaction, searchParams]);

  return (
    <div className="root">
      <TopBar transaction={transaction} signIn={true} />
      <div className="content-container">
        <div className="content-token">
          {!os || supported === undefined || supported === null ? (
            <div className="checking">
              <div>
                <CircularProgress className="indicator" />
              </div>
            </div>
          ) : !supported ? (
            !supportedOS ? (
              os && (
                <>
                  <NotSupportedOSSection
                    supportedBrowser={supportedBrowser}
                    browser={browser}
                    os={os}
                  />
                </>
              )
            ) : !supportedBrowser ? (
              <>
                <NotSupportedBrowserSection browser={browser} />
              </>
            ) : (
              <Typography>{t("Installation.systemNotSupported")}</Typography>
            )
          ) : supportedDesktopOSes.includes(os) ? (
            checking ? (
              <div className="checking">
                <div>
                  <CircularProgress className="indicator" />
                </div>
                {os && supportedDesktopOSes.includes(os) && (
                  <div>{t("Installation.checkingExtension")}</div>
                )}
              </div>
            ) : isClientAvailable ? (
              <ExtensionInstalledSection
                browser={browser}
                navigator={props.navigator}
                ongoingTransaction={ongoingTransaction}
              />
            ) : supportedBrowsers.includes(browser ?? "") ? (
              <InstallInstructionsSection
                invited={invited}
                browser={browser ?? ""}
                navigator={navigator}
                isPrivate={isPrivate}
              />
            ) : (
              <Typography>{t("Installation.noInstructions")}</Typography>
            )
          ) : os && supportedMobileOSes.includes(os) ? (
            os === "Android" ? (
              <ConnectSection
                goToInstallation={async () => {
                  await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interaction
                  window.open(playStoreLink, "_self");
                }}
                organization={transaction?.organization?.name}
                organizationIconUrl={transaction?.organization?.logoUrl}
                mobile={true}
                isSkippable={undefined}
                invited={invited ?? false}
              />
            ) : supportedIOSes.includes(os as OS) ? (
              <ConnectSection
                goToInstallation={async () => {
                  await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interaction
                  console.debug("testing:redirectedToIOSAppStore");
                  window.open(appStoreLink, "_self");
                }}
                organization={transaction?.organization?.name}
                organizationIconUrl={transaction?.organization?.logoUrl}
                mobile={true}
                isSkippable={undefined}
                invited={invited ?? false}
              />
            ) : (
              <Typography>{t("Installation.noInstructions")}</Typography>
            )
          ) : (
            <Typography>{t("Installation.noInstructions")}</Typography>
          )}
        </div>
      </div>
    </div>
  );
};
export default Installation;
