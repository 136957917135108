import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./CheckingSection.css";
import "../../General/general.css";
import { CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Transaction } from "../../API/XFA_DEVICE_API";

export interface CheckingSectionProps {
  t: (key: string) => string;
  skip: () => void;
  transactionIsSkippable: boolean | undefined;
  deviceCheck: boolean;
  transaction?: Transaction;
}

const CheckingSection: React.FC<CheckingSectionProps> = ({
  t,
  skip,
  transactionIsSkippable,
  deviceCheck,
  transaction,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // Log to Sentry after 5 seconds
      Sentry.captureMessage(
        transaction
          ? transaction!.toString()
          : "Checking takes longer than 5 seconds - no transaction found"
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, [transaction]);

  return (
    <div className="general">
      <Typography className="header">
        {deviceCheck ? t("Token.loading") : t("Token.checking")}
      </Typography>
      <div className="checking">
        <div className="loading">
          <div>
            <CircularProgress className="indicator" />
          </div>
        </div>

        {transactionIsSkippable === true && (
          <div className="button-container">
            <Button className="skip-button" onClick={skip} size="small">
              <Typography className="skip-text">{t("Token.skip")}</Typography>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckingSection;
