import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Home from "./components/Home/Home";
import { BrowserRouter } from "react-router-dom";
import { ClientApiProvider } from "./components/Extension/ClientApiContext";

export const XFAtheme = createTheme({
  palette: {
    primary: {
      main: "#10131A",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Manrope",
    h4: {},
  },
  components: {
    MuiAlert: { defaultProps: { variant: "filled" } },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "black",
          color: "white",
          fontFamily: "Manrope",
          borderRadius: "8px",
          padding: "12px",
        },
        arrow: {
          color: "black",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: "#E6E7EB",
          borderLeftWidth: 2,
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderColor: "#E6E7EB",
          borderLeftWidth: "2px",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            width: "32px",
            height: "32px",
          },
        },
        text: {
          fontFamily: "Manrope, sans-serif",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "22px",
          letterSpacing: "0em",
          textAlign: "center",
          fill: "#fff",
          marginTop: "2px",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: "0px !important",
        },
        iconContainer: {
          marginLeft: "-4px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
        },
      },
    },
  },
});

export interface AppProps {
  navigator: any;
  browser: any;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  return (
    <ThemeProvider theme={XFAtheme}>
      <ClientApiProvider navigator={props.navigator} browser={props.browser}>
        <div className="App">
          <BrowserRouter>
            <Home navigator={props.navigator} browser={props.browser} />
          </BrowserRouter>
        </div>
      </ClientApiProvider>
    </ThemeProvider>
  );
};

export default App;
