import { goToMfa } from "../../Navigation/Navigation";
import {
  ApiError,
  DeviceApi,
  Posture,
  Transaction,
} from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";
import {
  checkBrowser,
  checkOS,
  supportedMobileOSes,
  TokenStatus,
} from "../../System/System";

const key = "transactionId";

export const createTransaction = async (
  redirectUrl: string,
  applicationId?: string,
  email?: string
): Promise<Transaction> => {
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const response = await deviceClient.default.postToken({
    redirectUrl: redirectUrl,
    applicationId: applicationId,
    email: email,
  });

  const transactionId = response.transactionId;
  saveTransactionID(transactionId);
  return response;
};

export const getTransactionID = () => {
  return localStorage.getItem(key);
};

export const saveTransactionID = (transactionId: string) => {
  localStorage.setItem(key, transactionId);
};

export const wipeTransactionID = () => {
  localStorage.removeItem(key);
};

export const verifyTransaction = (transaction: Transaction): Boolean => {
  return true;
  /* TODO not yet implemented in device API
  if (transaction.ttl > new Date().getTime()) {
    return true;
  } else {
    return false;
  }
  */
};

export const getTransaction = async (transactionId: string) => {
  const deviceClient = new DeviceApi(deviceApiConfig);
  const transaction = await deviceClient.default.getToken(transactionId);
  return transaction;
};

export const skipTransaction = async (
  deviceClient: DeviceApi,
  transactionId: string
) => {
  const os = checkOS(window.navigator);
  const browser = checkBrowser(window.navigator);
  const userAgent = window.navigator.userAgent;
  const posture: Posture = {
    skip: true,
  };
  if (os) {
    posture.os = {
      name: os,
    };
  }
  if (browser) {
    posture.browser = {
      name: browser,
      user_agent: userAgent,
    };
  }

  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const transaction = await deviceClient.default
    .putToken(transactionId, undefined, undefined, posture)
    .catch((error: ApiError) => {
      throw new Error(error.message);
    });
  if (!transaction || transaction.status !== "SKIPPED") {
    throw new Error("Transaction not completed");
  }

  return transaction;
};

export const unsupportedTransaction = async (
  deviceClient: DeviceApi,
  transactionId: string
) => {
  const os = checkOS(window.navigator);
  const browser = checkBrowser(window.navigator);
  const userAgent = window.navigator.userAgent;
  const posture: Posture = {
    unsupported: true,
  };
  if (os) {
    posture.os = {
      name: os,
    };
  }
  if (browser) {
    posture.browser = {
      name: browser,
      user_agent: userAgent,
    };
  }

  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const transaction = await deviceClient.default
    .putToken(transactionId, undefined, undefined, posture)
    .catch((error: ApiError) => {
      throw new Error(error.message);
    });
  if (!transaction || transaction.status !== "UNSUPPORTED") {
    throw new Error("Transaction not completed");
  }
  return transaction;
};

export const redirectToApplication = (
  transaction: Transaction,
  wipeTransactionID: () => void,
  navigate: any,
  setTokenStatus: (status: TokenStatus) => void,
  setMfa: (mfa: boolean) => void,
  searchParams: URLSearchParams
) => {
  const os = checkOS(window.navigator);

  //check if mfa is done
  if (
    transaction.decisions?.mfa?.status === "BLOCKING" &&
    ((os && !supportedMobileOSes.includes(os)) ||
      transaction.status === Transaction.status.UNSUPPORTED ||
      transaction.status === Transaction.status.SKIPPED)
  ) {
    setMfa(true);
    goToMfa(navigate, transaction.transactionId);
    return;
  }

  //check if redirectUrl is present
  if (!transaction.redirectUrl) {
    setTokenStatus("Error");
    throw new Error("No redirectUrl present");
  }

  //check if token is present
  if (!transaction.access_token) {
    console.log("no access token");
    setTokenStatus("Error");
    throw new Error("No token present");
  }

  //add token to redirectUrl as base64 encoded json
  const urlWithToken = new URL(transaction.redirectUrl);
  urlWithToken.searchParams.set("token", transaction.access_token);

  //prevent redirect (for rendering stories - tests)
  if (
    searchParams.has("redirect") &&
    searchParams.get("redirect") === "false"
  ) {
    console.log("Redirect prevented");
    return;
  }

  //clean out transactionId
  wipeTransactionID();

  //actual redirect
  window.location.assign(urlWithToken);
};
