import { useCallback } from "react";
import { DeviceApi, Transaction } from "../../API/XFA_DEVICE_API";
import { skipTransaction } from "../utils/transactionHelpers";
import { deviceApiConfig } from "../../Config";
import { TokenStatus } from "../../System/System";
import { useTranslation } from "react-i18next";

export const useSkipTransaction = (
  transaction: Transaction | undefined,
  setError: (error: string) => void,
  setTokenStatus: (status: TokenStatus) => void,
  setTransaction: (transaction: Transaction) => void,
  endTransaction: (transaction: Transaction) => void
) => {
  const { t } = useTranslation();
  return useCallback(() => {
    if (!transaction) {
      throw new Error("TransactionId not set");
    }

    if (transaction?.redirectUrl === "") {
      setError(t("Token.errors.noUrl"));
      setTokenStatus("Error");
      return;
    }

    const deviceClient = new DeviceApi(deviceApiConfig);

    skipTransaction(deviceClient, transaction.transactionId)
      .then((transaction) => {
        setTransaction(transaction);
        endTransaction(transaction);
      })
      .catch((error) => {
        console.error(error);
        setError(t("Token.errors.skip"));
        setTokenStatus("Error");
      });
  }, [
    transaction,
    setError,
    setTokenStatus,
    setTransaction,
    t,
    endTransaction,
  ]);
};
