import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { DeviceApi } from "../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../Config";
import { useSearchParams } from "react-router-dom";
import { ErrorSection } from "../Token/sections/ErrorSection";
import { CircularProgress } from "@mui/material";
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";
import "../General/general.css";
import "../Token/Token.css";
import TopBar from "../General/TopBar";

export interface ConfirmMfaProps {
  navigator: any;
  browser: any;
}

const ConfirmMfa: React.FC<ConfirmMfaProps> = (props: ConfirmMfaProps) => {
  const { t } = useTranslation();

  const [error, setError] = React.useState<string | undefined>(undefined);
  const [confirmed, setConfirmed] = React.useState(false);
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (confirmed) return;
    if (!searchParams.has("transactionId") || !searchParams.has("challenge")) {
      setError(t("mfa.paramError"));
    }
    deviceClient.default
      .getConfirmMfa(
        searchParams.get("transactionId") as string,
        searchParams.get("challenge") as string
      )
      .then(() => {
        setConfirmed(true);
      })
      .catch((error) => {
        if (!confirmed) {
          setError(t("mfa.confirmError"));
        }
        console.log(error);
      });
  }, [confirmed, deviceClient.default, searchParams, t]);

  return (
    <div className="root">
      <TopBar transaction={null} signIn={false} />
      <div className="content-container">
        <div className="content-token">
          <div className="general">
            <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
            <div>
              <Typography className="mfa-header">
                {confirmed ? t("mfa.titleConfirm") : t("mfa.title")}
              </Typography>
              {error !== undefined ? (
                <ErrorSection t={t} error={error} />
              ) : confirmed ? (
                <div className="mfa-content">{t("mfa.contentConfirm")}</div>
              ) : (
                <div>
                  <CircularProgress className="indicator" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmMfa;
