import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress } from "@mui/material";
import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  Credential,
  DeviceApi,
  LoginFinishRequest,
  RegisterFinishRequest,
  Transaction,
} from "../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../Config";
import { ErrorSection } from "../Token/sections/ErrorSection";
import { checkOS, OS } from "../System/System";
import useTransaction from "../Token/hooks/useTransaction";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;
import { wipeTransactionID } from "../Token/utils/transactionHelpers";
import { useSearchParams } from "react-router-dom";
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import "../General/general.css";
import "./Mfa.css";
import TopBar from "../General/TopBar";
import { useClientApiContext } from "../Extension/ClientApiContext";

export interface MfaProps {
  navigator: any;
  browser: any;
}

const Mfa: React.FC<MfaProps> = (props: MfaProps) => {
  const { t } = useTranslation();

  const [error, setError] = React.useState<string | undefined>(undefined);
  const [waitForConfirmation, setWaitForConfirmation] =
    React.useState<boolean>(false);
  const [waitForEmailConfirmation, setWaitForEmailConfirmation] =
    React.useState<boolean>(false);
  const [enroll, setEnroll] = React.useState<boolean>(false);
  const [loginCalled, setLoginCalled] = React.useState<boolean>(false);
  const [registerCalled, setRegisterCalled] = React.useState<boolean>(false);
  const [pendingAction, setPendingAction] = React.useState<boolean>(true);
  const [os, setOS] = React.useState<undefined | OS>(undefined);
  React.useEffect(() => {
    setOS(checkOS(navigator));
  }, []);
  const { transaction, transactionError, updateTransaction } = useTransaction(
    props.navigator
  );
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;
  const [searchParams] = useSearchParams();
  const { clientApi } = useClientApiContext();

  React.useEffect(() => {
    if (transactionError) {
      setError(transactionError);
    }
  }, [transactionError]);

  React.useEffect(() => {
    if (error !== undefined || error === "") return;
    if (!transaction) return;
    if (
      transaction.status === Transaction.status.UNSUPPORTED ||
      transaction.status === Transaction.status.SKIPPED
    ) {
      return;
    }
    if (loginCalled) return;
    setLoginCalled(true);
    clientApi.getCredentialID((reply: any) => {
      if (
        transaction?.decisions?.mfa?.credentials?.credentials?.some(
          (credential: Credential) => {
            return reply === credential.id;
          }
        )
      ) {
        // start login
        deviceClient.default
          .postLoginBegin(transaction?.transactionId!)
          .then((response) => {
            // call extension
            clientApi.getCredential((reply: LoginFinishRequest) => {
              // finish login
              deviceClient.default
                .postLoginFinish(
                  response.SessionId!,
                  transaction?.transactionId!,
                  reply
                )
                .then(() => {
                  deviceClient.default
                    .getToken(transaction?.transactionId!)
                    .then((transaction) => {
                      if (transaction?.decisions?.mfa?.status === "OK") {
                        redirectToApplication(transaction);
                      } else {
                        if (
                          transaction?.decisions?.mfa?.error != null &&
                          transaction?.decisions?.mfa?.error !== ""
                        ) {
                          setError(transaction?.decisions?.mfa?.error);
                        } else {
                          setError("something went wrong");
                        }
                        setWaitForConfirmation(false);
                        setPendingAction(false);
                      }
                    })
                    .catch((error) => {
                      setError(error);
                    });
                })
                .catch((error) => {
                  setError(error);
                });
            }, response);
          })
          .catch((error) => {
            setError(error);
          });
      } else {
        setPendingAction(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, props, transaction, loginCalled]);

  //refresh the transaction periodically (1 second)
  React.useEffect(() => {
    if (error !== undefined || error === "") return; //no need to refresh when there is an error
    if (!waitForConfirmation) return; //only refresh when waiting for confirmation
    if (!transaction?.transactionId) return; //no transaction to refresh
    const interval = setInterval(() => {
      updateTransaction(transaction?.transactionId);
    }, 1000);
    return () => clearInterval(interval);
  }, [
    error,
    os,
    transaction?.transactionId,
    updateTransaction,
    waitForConfirmation,
  ]);

  React.useEffect(() => {
    if (error !== undefined || error === "") return;
    if (
      enroll &&
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "OK"
    ) {
      register();
    } else if (
      !enroll &&
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "OK"
    ) {
      redirectToApplication(transaction!);
    } else if (
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "BLOCKING" &&
      transaction?.decisions?.mfa?.error !== ""
    ) {
      setError(transaction?.decisions?.mfa?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, transaction, enroll, waitForConfirmation]);

  React.useEffect(() => {
    if (
      !transaction ||
      (transaction!.status !== Transaction.status.UNSUPPORTED &&
        transaction!.status !== Transaction.status.SKIPPED)
    ) {
      return;
    }
    if (waitForConfirmation) return;
    setWaitForConfirmation(true);
    deviceClient.default.postToken1(transaction?.transactionId!, {
      action: action.SEND_MFA,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const register = React.useCallback(() => {
    if (error !== undefined || error === "") return; //no need to refresh when there is an error
    if (registerCalled) return;
    setRegisterCalled(true);
    // start register
    deviceClient.default
      .postRegisterBegin(transaction?.transactionId!)
      .then((response) => {
        // call extension
        clientApi.createCredential((reply: RegisterFinishRequest) => {
          // finish register
          deviceClient.default
            .postRegisterFinish(
              transaction?.transactionId!,
              response.SessionId!,
              reply
            )
            .then(() => {
              deviceClient.default
                .getToken(transaction?.transactionId!)
                .then((transaction) => {
                  if (transaction?.decisions?.mfa?.status === "OK") {
                    redirectToApplication(transaction);
                  } else {
                    if (
                      transaction?.decisions?.mfa?.error != null &&
                      transaction?.decisions?.mfa?.error !== ""
                    ) {
                      setError(transaction?.decisions?.mfa?.error);
                    } else {
                      setError("something went wrong");
                    }
                    setWaitForConfirmation(false);
                    setPendingAction(false);
                  }
                })
                .catch((error) => {
                  setError(error);
                });
            })
            .catch((error) => {
              setError(error);
            });
        }, response);
      })
      .catch((error) => {
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    props,
    transaction?.transactionId,
    transaction?.user?.email,
    registerCalled,
  ]);

  const enrollDevice = React.useCallback(() => {
    if (error !== undefined || error === "") return; //no need to refresh when there is an error
    setEnroll(true);
    deviceClient.default
      .postToken1(transaction?.transactionId!, {
        action: action.SEND_MFA,
      })
      .then((response) => {
        if (response) {
          const transaction = response as Transaction;

          //on auto approval, register the device immediately
          if (transaction?.decisions?.mfa?.autoApproved === true) {
            setWaitForConfirmation(false);
            setPendingAction(true);
            register();
          } else {
            setWaitForConfirmation(true);
          }
        } else {
          setWaitForConfirmation(true);
        }
      })
      .catch((error) => {
        setWaitForConfirmation(true);
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, props, transaction]);

  const redirectToApplication = React.useCallback(
    (transaction: Transaction) => {
      //check if redirectUrl is present
      if (!transaction.redirectUrl) {
        setError("No redirectUrl present");
        return;
      }

      //check if token is present
      if (!transaction.access_token) {
        console.log("no access token");
        setError("No token present");
        return;
      }

      //add token to redirectUrl as base64 encoded json
      const urlWithToken = new URL(transaction.redirectUrl);
      urlWithToken.searchParams.set("token", transaction.access_token);

      //prevent redirect (for rendering stories - tests)
      if (
        searchParams.has("redirect") &&
        searchParams.get("redirect") === "false"
      ) {
        console.log("Redirect prevented");
        return;
      }

      //clean out transactionId
      console.log("cleaning out transactionId");
      wipeTransactionID();

      //actual redirect
      window.location.assign(urlWithToken);
    },
    [searchParams, setError]
  );
  const pending = waitForConfirmation || pendingAction;
  const firstMfaDevice =
    transaction?.decisions?.mfa?.credentials?.credentials === undefined ||
    transaction?.decisions?.mfa?.credentials?.credentials?.length === 0;

  return (
    <div className="root">
      <TopBar transaction={null} signIn={false} />
      <div className="content-container">
        <div className="content-token">
          <div className="general">
            <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
            <div className="mfa-header-content">
              <Typography className="mfa-header">
                {waitForConfirmation
                  ? t("mfa.title")
                  : t(pending ? "mfa.generalTitle" : "mfa.trustTitle")}
              </Typography>
              {pending ? <CircularProgress className="indicator" /> : null}
            </div>
            <div className="general">
              {error !== undefined ? (
                <ErrorSection t={t} error={error} />
              ) : pending ? (
                waitForConfirmation && !waitForEmailConfirmation ? (
                  <div>
                    <div>
                      {firstMfaDevice ? (
                        t("mfa.checkingFirstDevice")
                      ) : (
                        <div>
                          <Typography className="mfa-header-h2">
                            {t("mfa.trustedDeviceTitle")}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t("mfa.checking"),
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {!firstMfaDevice ? (
                      <div>
                        <Typography className="mfa-header-h2 second-title">
                          {t("mfa.emailTitle")}
                        </Typography>
                        <Button
                          variant="contained"
                          className="grey-button email-button"
                          size="large"
                          startIcon={<EmailIcon className="emailIcon" />}
                          onClick={() => {
                            setWaitForConfirmation(true);
                            setWaitForEmailConfirmation(true);
                            deviceClient.default.postToken1(
                              transaction?.transactionId!,
                              {
                                action: action.SEND_MFA,
                              },
                              undefined,
                              undefined,
                              undefined,
                              true
                            );
                          }}
                        >
                          {t("mfa.emailButton")}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ) : waitForConfirmation && waitForEmailConfirmation ? (
                  <div>
                    <div>
                      <Typography className="mfa-header-h2">
                        {t("mfa.checkEmailTitle")}
                      </Typography>
                      {t("mfa.checkEmailContent")}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <div>
                  <div className="mfa-content">{t("mfa.question")}</div>
                  <div className="mfa-buttons">
                    <Button
                      variant="contained"
                      className="grey-button"
                      size="large"
                      onClick={() => {
                        setWaitForConfirmation(true);
                        deviceClient.default.postToken1(
                          transaction?.transactionId!,
                          {
                            action: action.SEND_MFA,
                          }
                        );
                      }}
                    >
                      {t("mfa.noButton")}
                    </Button>
                    <Button
                      variant="contained"
                      className="black-button"
                      size="large"
                      onClick={enrollDevice}
                    >
                      {t("mfa.yesButton")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mfa;
