import {
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

//Icons
import WebAssetOffIcon from "@mui/icons-material/WebAssetOff";
import ChromeIcon from "../../../images/chrome.png";
import FirefoxIcon from "../../../images/firefox.png";
import EdgeIcon from "../../../images/edge.png";

export interface NotSupportedBrowserSectionProps {
  browser: any;
}

const NotSupportedBrowserSection: React.FC<NotSupportedBrowserSectionProps> = ({
  browser,
}) => {
  const { t } = useTranslation();
  return (
    <div className="notSupported">
      <div>
        <WebAssetOffIcon className="BrowserNotSupportedIcon" />
      </div>
      <div>
        <Typography>
          {t("Installation.currentBrowser")}
          <Typography style={{ display: "inline", padding: 5 }} variant="h6">
            {browser}
          </Typography>
          {t("Installation.notSupported")}
        </Typography>
      </div>
      <div className="supported">
        <Typography>{t("Installation.supportedBrowsers")}</Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="supportedItem">
            <Link
              style={{ textDecoration: "none" }}
              href="https://chrome.google.com/webstore/detail/xfa-securing-your-device/ajobakkejbhbmiiodbcmobomohngjdhc/related?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card className="supportedCard">
                <CardActionArea>
                  <CardContent sx={{ p: 2 }}>
                    <img
                      alt="chrome"
                      src={ChromeIcon}
                      className="supportedIcon chromeIcon"
                    />
                    <div>{t("Installation.chrome")}</div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </div>
          <div className="supportedItem">
            <Link
              style={{ textDecoration: "none" }}
              href="https://addons.mozilla.org/en-US/firefox/addon/xfa-securing-your-device/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card className="supportedCard">
                <CardActionArea>
                  <CardContent sx={{ p: 2 }}>
                    <img
                      alt="firefox"
                      src={FirefoxIcon}
                      className="supportedIcon firefoxIcon"
                    />
                    <div>{t("Installation.firefox")}</div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </div>
          <div className="supportedItem">
            <Link
              style={{ textDecoration: "none" }}
              href="https://microsoftedge.microsoft.com/addons/detail/xfa-securing-your-device/ikhelogcjkfnfccibbceakadlkbilhbg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card className="supportedCard">
                <CardActionArea>
                  <CardContent sx={{ p: 2 }}>
                    <img
                      alt="edge"
                      src={EdgeIcon}
                      className="supportedIcon edgeIcon"
                    />
                    <div>{t("Installation.edge")}</div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotSupportedBrowserSection;
