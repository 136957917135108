import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Browser,
  IframePath,
  OS,
  getBrowser,
  getOSOfPlatform,
} from "../../../System/System";
import "./UpdateInstructionsModal.css";
import { GuidesGenerator } from "./GuidesGenerator";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface UpdateInstructionsModalProps {
  platform: OS | null;
  open: boolean;
  handleClose: () => void;
  path: IframePath;
  browser?: Browser;
  version?: string;
}

const UpdateInstructionsModal: React.FC<UpdateInstructionsModalProps> = ({
  platform,
  open,
  handleClose,
  path,
  browser,
  version,
}) => {
  const [iframeDimensions, setIframeDimensions] = useState({
    height: "100px",
    width: "100%",
  });

  useEffect(() => {
    const handleResizeMessage = (event: MessageEvent) => {
      if (event.data.type === "resize-iframe") {
        setIframeDimensions({
          height: `${event.data.height + 32}px`,
          width: `${event.data.width}px`,
        });
      }
    };

    window.addEventListener("message", handleResizeMessage);
    return () => {
      window.removeEventListener("message", handleResizeMessage);
    };
  }, []);

  return (
    <Dialog
      style={{ padding: 16, height: "auto" }}
      open={open}
      PaperProps={{
        className: "Token.updateInstructionsModal",
      }}
    >
      <div
        style={{
          padding: 16,
          height: iframeDimensions.height,
          position: "relative",
        }}
      >
        <GuidesGenerator
          path={path}
          os={getOSOfPlatform(platform)}
          browser={getBrowser(browser)}
          version={version}
        />
        <IconButton className="icon-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </Dialog>
  );
};
export default UpdateInstructionsModal;
