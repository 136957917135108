/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DecisionVersion = {
    /**
     * status of decision
     */
    status: DecisionVersion.status;
    /**
     * the latest version
     */
    latest_version: string;
    time_since_outdated: number;
    time_till_blocked?: number;
};
export namespace DecisionVersion {
    /**
     * status of decision
     */
    export enum status {
        OK = 'OK',
        WARNING = 'WARNING',
        BLOCKING = 'BLOCKING',
    }
}

